import { memo } from 'react';
import listingSprite from '@pepita-fe/sprite-listing-search/sprite.svg';
import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';

import {
  PepitaSelect,
  type PepitaSelectProps,
} from 'src/libs/ui/pepita-select';

import type { SORT_DIRECTION } from './types';

import css from './styles.module.scss';

export type SortingSelectOption = PepitaSelectProps['value'] & {
  direction?: SORT_DIRECTION;
};

interface SortingSelectProps<T extends SortingSelectOption> {
  options: T[];
  value: T;
  onChange: (option: T) => void;
  customClass?: string;
}

function SortingSelectComponent<T extends SortingSelectOption>({
  options,
  value,
  onChange,
  customClass,
}: SortingSelectProps<T>) {
  const { trans } = useTranslations();

  return (
    <PepitaSelect
      placeholder={trans('act_order', {
        capitalize: true,
      })}
      title={trans('act_order', {
        capitalize: true,
      })}
      customClass={clsx(css['in-sortingSelect'], customClass)} // necessary for old saved map
      controlCustomClass="cy-sorting-select"
      options={options}
      value={value}
      onChange={(option) => onChange(option as T)}
      icon={{
        name: 'order',
        spriteUrl: listingSprite,
      }}
      confirmLabel={trans('act_confirm')}
    />
  );
}

export const SortingSelect = memo(
  SortingSelectComponent
) as typeof SortingSelectComponent;
