import { useEffect, useMemo, useState } from 'react';
import { useIsOnClient } from '@indomita-react/custom-hooks';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import { useAtomValue, useSetAtom } from 'jotai';

import { bannersDataAtom } from 'src/atoms/bannersDataAtom';
import { bannersSlotsAtom } from 'src/atoms/bannersSlotsAtom';

import { BannerSlot } from 'src/components/BannerSlot';
import { loadSlots, unloadBanner } from 'src/components/BannerSlot/banners';

import { useBanners } from 'src/hooks/useBanners';

import type { Banner } from 'src/types/banner';

import { getValidBanners } from './getValidBanner';

interface AgencyListBannerProps {
  position: string | number;
  isBottom?: boolean;
  customClass?: string;
}

export interface AgencyBannerConfig {
  [key: string | number]: string;
}
const AGENCY_BANNER_CONFIG: AgencyBannerConfig = {
  aside: 'banner_aside_1',
  3: 'banner_infralisting_1',
  bottom: 'banner_bottom_1',
  bottom_after_pagination: 'banner_afterpagination_agency_1',
};

const AGENCY_BANNER_CONFIG_MOBILE: AgencyBannerConfig = {
  bottom_after_pagination: 'banner_afterpagination_agency_1_mobile',
};

const getId = (
  position: string | number,
  validBanners: Banner[],
  agencyBannerConfig: AgencyBannerConfig
) => {
  if (validBanners.length > 0) {
    const bannerKeys = validBanners.map((b) => b.key);

    if (bannerKeys.includes(agencyBannerConfig[position])) {
      const bannerSelected = validBanners.find(
        (b) => b.key === agencyBannerConfig[position]
      );

      return bannerSelected ? bannerSelected.id : null;
    }
  }

  return null;
};

export function AgencyListBanner({
  position,
  customClass,
  isBottom,
}: AgencyListBannerProps) {
  const [bannerReady, setBannerReady] = useState(false);
  const banners = useAtomValue(bannersDataAtom);
  const setBannersSlot = useSetAtom(bannersSlotsAtom);
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });
  const isOnClient = useIsOnClient();

  const agencyBannerConfig = useMemo(() => {
    if (maxScreenWidthSm) {
      return AGENCY_BANNER_CONFIG_MOBILE;
    }

    return AGENCY_BANNER_CONFIG;
  }, [maxScreenWidthSm]);

  const validBanners = getValidBanners(banners, agencyBannerConfig);

  useBanners(true);

  useEffect(() => {
    if (!banners || !isOnClient) return;

    loadSlots(validBanners, banners.settings).then((slots) => {
      setBannersSlot(slots);
      setBannerReady(true);
    });

    return () => {
      unloadBanner();
    };
  }, [banners, agencyBannerConfig, isOnClient, setBannersSlot, validBanners]);

  if (!banners) {
    return null;
  }

  return bannerReady ? (
    <BannerSlot
      id={getId(position, validBanners, agencyBannerConfig)}
      customClass={customClass}
      isHtmlList={typeof position === 'number'}
      isBottom={isBottom}
    />
  ) : null;
}
