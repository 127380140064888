import type { MutableRefObject } from 'react';
import { useCallback, useState } from 'react';

import { useClickOutside } from 'src/hooks/useClickOutside';
import { useListener } from 'src/hooks/useListener';

export const useDropdown = (
  container: MutableRefObject<Nullable<HTMLElement>>
) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => {
    setIsOpen((state) => !state);
  }, [container]);

  const close = useCallback(() => {
    setIsOpen(false);
  }, [container]);

  /**
   * This handles clicks on the document (close) and on the element (open)
   */
  useListener(container, 'click', toggle);
  useClickOutside(container, close, isOpen);

  return {
    isOpen,
  };
};
