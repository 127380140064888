import clsx from 'clsx';

import css from './styles.module.scss';

interface AgencyTitleProps {
  title: string;
  href: string;
  isSmall: boolean;
  highlighted: boolean;
}

export function AgencyTitle({
  title,
  href,
  highlighted,
  isSmall,
}: AgencyTitleProps) {
  return (
    <a
      href={href}
      className={clsx(
        css['in-agencyTitle'],
        highlighted && css['in-agencyTitle--highlighted'],
        isSmall && css['is-compact']
      )}
    >
      {title}
    </a>
  );
}
