import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';

import { useAtomicStateAtomSetState } from 'src/atoms/atomic-state';
import { currentPageAtom } from './atoms';

import type { Language } from 'src/types/translations';

import { alternateAbsoluteURL } from 'src/utils/url';
import { generatePaginationRange, makePaginationBuilder } from './utils';

import type { PaginationItemProps } from './PaginationItem';

export const usePaginationItems = (
  currentPage: number,
  totalPages: number,
  pagesRange: number,
  shallow = false
) => {
  const { asPath, defaultLocale, locale } = useRouter();
  const indexSplit = asPath.indexOf('?');
  const pathname = alternateAbsoluteURL(
    indexSplit !== -1 ? asPath.substring(0, indexSplit) : asPath,
    defaultLocale as Language,
    locale as Language
  );
  const searchParams = useSearchParams();
  const setPagination = useAtomicStateAtomSetState(currentPageAtom);

  // Questo causa un errore "silenzioso": se il range non è definito la paginazione non c'è
  if (!pagesRange) {
    return {
      range: [] as PaginationItemProps[],
    };
  }

  pagesRange = Math.min(pagesRange, totalPages);

  // range is the array of pages which we will show as pagination items
  // it is an array of numbers (the pages) with some null values
  // (corresponding with the position of ... elements)
  const range = generatePaginationRange(currentPage, totalPages, pagesRange);

  // All interesting values for pagination
  const nextPage = Math.min(currentPage + 1, totalPages);
  const prevPage = Math.max(currentPage - 1, 1);
  const lastPage = Math.min(currentPage + pagesRange - 1, totalPages);
  const firstPage = Math.max(currentPage - pagesRange + 1, 1);

  const onPageClick = (value: 'next' | 'prev' | 'last' | 'first' | string) => {
    const value2Page: {
      [k in 'next' | 'prev' | 'last' | 'first']: number;
    } = {
      next: nextPage,
      prev: prevPage,
      last: lastPage,
      first: firstPage,
    };

    setPagination(value2Page[value] || Math.min(parseInt(value, 10) || 1));
  };

  const makePaginationItem = makePaginationBuilder(
    currentPage,
    totalPages,
    pathname,
    searchParams,
    range?.[range.length - 2] === null, // Disable last page only when we have a ... element near the end
    shallow
  );

  const prev =
    currentPage > 1 ? makePaginationItem(prevPage) : makePaginationItem();

  const next =
    currentPage < totalPages
      ? makePaginationItem(nextPage)
      : makePaginationItem();

  const first =
    currentPage > 1 ? makePaginationItem(firstPage) : makePaginationItem();

  const last =
    currentPage < totalPages
      ? makePaginationItem(lastPage)
      : makePaginationItem();

  return {
    range: range.map(makePaginationItem),
    prev,
    next,
    first,
    last,
    onPageClick,
  };
};
