import { memo, useEffect } from 'react';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import clsx from 'clsx';
import { useAtomValue } from 'jotai';

import { bannersSlotsAtom } from 'src/atoms/bannersSlotsAtom';

import { PepitaListItem } from 'src/libs/ui/pepita-list';

import { displayBanner, initDisplaySingleBanner } from './banners';

import css from './styles.module.scss';

interface BannerSlotProps {
  id: string | null;
  customClass?: string;
  isHtmlList?: boolean;
  isBottom?: boolean;
  trackingKey?: string;
}

export const BannerSlot = memo(
  ({
    id,
    customClass,
    isHtmlList = false,
    isBottom = false,
    trackingKey,
  }: BannerSlotProps) => {
    const bannerSlots = useAtomValue(bannersSlotsAtom);

    const shouldShowBanners = id && bannerSlots[id];

    const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

    useEffect(() => {
      if (!shouldShowBanners) return;
      // Load and display this banner
      initDisplaySingleBanner(id);
      displayBanner(id, bannerSlots[id]);
    }, [maxScreenWidthSm, bannerSlots, shouldShowBanners, id]);

    if (!shouldShowBanners) return null;

    if (isHtmlList) {
      return (
        <PepitaListItem
          id={id}
          customClass={clsx(css['in-bannerSlot__item'], customClass)}
          data-tracking-key={trackingKey}
        />
      );
    }

    return (
      <div
        id={id}
        className={clsx(isBottom && css['in-bannerSlot__item'], customClass)}
        data-tracking-key={trackingKey}
      />
    );
  }
);

BannerSlot.displayName = 'BannerSlot';
