import { useCallback } from 'react';
import { useRouter } from 'next/router';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import { geographySearchAtom } from 'src/components/GeographySearch';
import {
  ENTITY_TYPE,
  getGeographyEntityLabel,
  getGeographyEntityLabelZone,
} from 'src/components/GeographySearch/utils';

import { removeEmptyKeys } from 'src/utils/object';

export const useAgencyListTrackingData = () => {
  const { locale } = useRouter();
  const geography = useAtomicStateAtomValue(geographySearchAtom);

  const agencyListTrackingData = useCallback(() => {
    //for microzone and macrozone the values equal to the empty string are removed
    //and the result is transformed back into an array
    const microzones = Object.values(
      removeEmptyKeys(
        getGeographyEntityLabelZone(geography, ENTITY_TYPE.microzone)
      )
    );
    const macrozones = Object.values(
      removeEmptyKeys(
        getGeographyEntityLabelZone(geography, ENTITY_TYPE.cityZone)
      )
    );
    const city = getGeographyEntityLabel(geography, ENTITY_TYPE.city);
    const region = getGeographyEntityLabel(geography, ENTITY_TYPE.region);
    const province = getGeographyEntityLabel(geography, ENTITY_TYPE.province);
    const nation = getGeographyEntityLabel(geography, ENTITY_TYPE.country);

    return {
      language: locale || undefined,
      agencies_country: nation || undefined,
      agencies_region: region || undefined,
      agencies_province: province || undefined,
      agencies_city: city || undefined,
      agencies_macrozone:
        macrozones.length > 0 ? macrozones.join(', ') : undefined,
      agencies_microzone:
        microzones.length > 0 ? microzones.join(', ') : undefined,
    };
  }, [geography, locale]);

  return { agencyListTrackingData };
};
