import { LISTING_TYPE } from 'src/constants/real-estate';

export const formatRealEstateInfo = (
  realEstateList: { id: number; type?: LISTING_TYPE }[]
) => {
  return realEstateList.reduce(
    (acc, item, index) => {
      acc[`Listing ${index + 1} ID`] =
        item.type === LISTING_TYPE.PROJECT ? `p-${item.id}` : `${item.id}`;

      return acc;
    },
    {} as Record<string, string>
  );
};
