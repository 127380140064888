import type { AgencyListItem } from 'src/types/agencies-list';

export const getAgencyForContact = (agency: AgencyListItem) => {
  const { phones } = agency;

  const phonesAgency = phones.length
    ? phones.map((phone) => ({
        type: phone.isVirtual ? ('vTel1' as const) : phone.type,
        value: phone.value,
      }))
    : [];

  return {
    ...agency,
    phones: phonesAgency,
  };
};
