import type { MutableRefObject } from 'react';
import { memo, useEffect, useMemo, useState } from 'react';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import { clsx } from 'clsx';

import { PepitaList, PepitaListItem } from 'src/libs/ui/pepita-list';

import type { BreadcrumbData } from 'src/types/breadcrumb';

import { getHasItemProp } from './utils/getHasItemProp';

import { BreadCrumbItem } from './BreadCrumbItem';

import css from './styles.module.scss';

export const Breadcrumb = memo(function Breadcrumb({
  config,
  container,
  customClass,
  hasMicrodata = false,
}: {
  config: BreadcrumbData;
  container?: MutableRefObject<Nullable<HTMLElement>>;
  customClass?: string;
  hasMicrodata?: boolean;
}) {
  const [minScreenWidthSm] = useBreakpoint({ from: 'sm' });
  const [mainRightBounding, setMainRightBounding] =
    useState<Optional<number>>(undefined);

  const mainBounding = useMemo(() => {
    if (minScreenWidthSm && container) {
      return container.current?.getBoundingClientRect();
    }

    return undefined;
  }, [container, minScreenWidthSm]);

  useEffect(() => {
    if (mainBounding) {
      setMainRightBounding(mainBounding.right);
    }
  }, [mainBounding]);

  return (
    <PepitaList
      customClass={clsx(css['in-breadcrumb'], customClass)}
      type="arrow"
      data-cy="breadcrumb"
      itemScope={hasMicrodata || undefined}
      itemType={hasMicrodata ? 'http://schema.org/BreadcrumbList' : undefined}
      items={config.map((option, i) => {
        // if it's in hasMicrodata, adding itemProp and itemType for all first-level breadcrumbs except
        // dropdowns without selected elements
        const hasItemProp = getHasItemProp(
          hasMicrodata,
          Boolean(option.items),
          Boolean(option.link)
        );

        return (
          <PepitaListItem
            key={i}
            itemProp={hasItemProp ? 'itemListElement' : undefined}
            itemScope={hasMicrodata || undefined}
            itemType={hasItemProp ? 'http://schema.org/ListItem' : undefined}
            customClass={
              option.type && css[`in-breadcrumb__item--${option.type}`]
            }
          >
            <BreadCrumbItem
              option={option}
              hasItemProp={hasItemProp}
              index={i}
              mainRightBounding={mainRightBounding}
            />
            {hasMicrodata && <meta itemProp="position" content={`${i + 1}`} />}
          </PepitaListItem>
        );
      })}
    />
  );
});
