import type { SyntheticEvent } from 'react';
import { useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';

import { PepitaButton } from 'src/libs/ui/pepita-button';

interface AgencyPhoneProps {
  type: 'green-phone' | 'vTel1' | 'cell' | 'tel2' | 'tel1' | 'vtel';
  phoneNumber?: string;
  onShowPhoneClick: () => void;
}

export function AgencyPhone({
  type,
  phoneNumber,
  onShowPhoneClick,
}: AgencyPhoneProps) {
  const [showPhone, setShowPhone] = useState(false);
  const { trans } = useTranslations();

  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setShowPhone(true);
    onShowPhoneClick();
  };

  return !showPhone ? (
    <PepitaButton
      data-type={type}
      icon={{ name: 'phone' }}
      onClick={handleClick}
    >
      {trans('lbl_phone')}
    </PepitaButton>
  ) : (
    <PepitaButton
      as="a"
      href={`tel:${phoneNumber}`}
      onClick={(e: SyntheticEvent) => {
        e.stopPropagation();
      }}
    >
      {phoneNumber}
    </PepitaButton>
  );
}
