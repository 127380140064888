import type { ReactNode } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';

import { isFeatureEnabled } from 'src/config/features-toggle';

import type { I18n } from 'src/utils/serverSideTrans';

import genericEmptyStateImg from './images/generic/EmptyStateSearch.svg';
import immoEmptyStateImg from './images/immo_empty_state.svg';

import css from './styles.module.scss';

type EmptyStateProps = {
  children?: ReactNode;
  customClass?: string;
  type: 'list' | 'agency';
  text?: string;
};

const getTextByType = (
  type: EmptyStateProps['type'],
  trans: I18n['trans']
): string => {
  if (type === 'agency') {
    return trans('agency_view_no_result_ads');
  }

  return trans('map_view_no_result_ads');
};

export function EmptyState({
  type,
  children,
  customClass,
  text,
}: EmptyStateProps) {
  const { trans } = useTranslations();

  return (
    <div
      className={clsx(
        css['in-emptyState'],
        type === 'list' && css['in-emptyState--list'],
        customClass
      )}
    >
      <picture className={css['in-emptyState__image']}>
        <img
          src={
            isFeatureEnabled('GENERIC_IMAGE')
              ? genericEmptyStateImg
              : immoEmptyStateImg
          }
          alt=""
          height="160"
        />
      </picture>

      <p className={css['in-emptyState__title']}>
        {trans('listing_empty_results_title', {
          capitalize: true,
        })}
        ...
      </p>
      <p>{text ?? getTextByType(type, trans)}</p>
      {children}
    </div>
  );
}
