import type { BannerConfig } from 'src/types/banner';

export function dfp() {
  //@ts-ignore
  window.googletag = window.googletag || {};
  //@ts-ignore
  window.googletag.cmd = window.googletag.cmd || [];

  //@ts-ignore
  return window.googletag;
}

// Load all banner slots
export const loadSlots = (
  banners: BannerConfig['banners'],
  settings: BannerConfig['settings'],
  idMacrozona?: string,
  idRegione?: string
) => {
  return new Promise<{ [key: string]: { dfp: unknown; criteo: unknown } }>(
    (resolve) => {
      dfp().cmd.push(() => {
        const slots: {
          [key: string]: { dfp: unknown; criteo: unknown };
        } = {};

        banners.forEach((banner) => {
          slots[banner.id] = {
            dfp: initSlot(banner, idMacrozona, idRegione),
            criteo: null,
          };
        });

        if (Object.keys(slots).length > 0) {
          // Configure SRA
          dfp().pubads().enableSingleRequest();

          // Sets `display:none` on empty slots
          if (settings?.nascondiSeVuoti) {
            dfp().pubads().collapseEmptyDivs();
          }

          // Disable initial load for banners
          dfp().pubads().disableInitialLoad();

          // Start ad fetching
          dfp().enableServices();

          // Saving slots for unload
          bannerSlots = { ...slots };
        }

        resolve(slots);
      });
    }
  );
};

// Init a dfp slot
let bannerSlots = {};
const initSlot = (banner, idMacrozona, idRegione) => {
  if (bannerSlots[banner.id]?.dfp) {
    dfp().destroySlots([bannerSlots[banner.id].dfp]);
  }

  const slot = dfp().defineSlot(
    banner.name,
    [parseInt(banner.width, 10), parseInt(banner.height, 10)],
    banner.id
  );

  /**
   * Sometimes no slots are returned 0_0
   */
  if (!slot) return;

  if (banner.setTargeting) {
    for (const key in banner.setTargeting) {
      slot.setTargeting(key, banner.setTargeting[key]);
    }
  }

  if (idMacrozona) {
    slot.setTargeting('Macrozona', idMacrozona);
  }

  if (idRegione) {
    slot.setTargeting('Regione', idRegione);
  }

  slot.addService(dfp().pubads());

  return slot;
};

// Unload a single banner
export const unloadBanner = () => {
  dfp().cmd.push(() => {
    Object.keys(bannerSlots).forEach((bannerID) => {
      if (bannerSlots[bannerID]?.dfp) {
        dfp().destroySlots([bannerSlots[bannerID].dfp]);
      }

      bannerSlots[bannerID] = undefined;
    });
  });
};

// Calls to display (with disableInitialLoad they won't
// show html in page)
export const initDisplaySingleBanner = (bannerID) => {
  dfp().cmd.push(() => {
    dfp().display(bannerID);
  });
};

// Call refresh to load and display banner html
export const displayBanner = (bannerID, slotData) => {
  if (slotData?.['dfp']) {
    dfp().pubads().refresh([slotData['dfp']]);
  }
};
