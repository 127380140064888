import { useCallback } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';

import { useAgencyListTrackingData } from 'src/tracking/ga/hooks/useAgencyListTrackingData';
import { useSendGA4Event } from 'src/tracking/ga/hooks/useSendGAEvent';
import { useIsMapCollapsed } from 'src/tracking/hooks/useIsMapCollapsed';
import { useTrack } from 'src/tracking/hooks/useTrack';
import { getSegmentAgencyFromAgencyListById } from 'src/tracking/segment/utils';
import type { UserHashedEmailAndPhone } from 'src/tracking/types';
import {
  formatTransactionId,
  getContactType,
  hashEmailAndPhone,
  isCourtAgency,
  TrackingDataAdvertiser,
} from 'src/tracking/utils';
import {
  ContactCategory,
  ContactSubType,
  EventName,
  GATrackingEvent,
  PurchaseAndContactEventValue,
} from 'src/tracking/utils/enums';

import type { AgencyListItem } from 'src/types/agencies-list';

import { http } from 'src/utils/client/http';

import type {
  TrackAgencyContactSubmitArg,
  TrackContactsClick,
} from '../../tracking/types';

export const useAgencyListItemTracking = (agency: AgencyListItem) => {
  const track = useTrack();
  const { user } = useAuthContext();
  const sendGA4Event = useSendGA4Event();
  const isMapCollapsed = useIsMapCollapsed();
  const { agencyListTrackingData } = useAgencyListTrackingData();

  const { id } = agency;

  const trackAgencyContactsClick = ({
    type,
    phoneType,
    isGreenPhone = false,
  }: TrackContactsClick) => {
    const agencyTrackingData = getSegmentAgencyFromAgencyListById(id);

    if (type === 'telephone') {
      http.get(`/stats/statsAgenzia.php`, {
        searchParams: {
          idAgenzia: id,
          typeTrack: 'mostraTelefono',
          fieldTrack: phoneType,
          source: 'L-AG-G',
          output: 'json',
          [Number(new Date()).toString()]: '',
        },
      });
    }

    track(EventName.AGENCY_CONTACT_REQUEST, {
      ...(agencyTrackingData || {}),
      'Type of contact': type === 'telephone' ? 'Telefono' : 'Messaggio',
      'Toll number': isGreenPhone,
    });
  };

  const trackAgencyContactSubmit = useCallback(
    ({
      agency,
      purchaseAndContactEventValue,
      isVirtualPhone,
      contactData,
    }: TrackAgencyContactSubmitArg) => {
      const transaction_id = formatTransactionId(
        purchaseAndContactEventValue,
        contactData?.contactId
      );
      const isCourt = isCourtAgency(agency.type);
      let contact_subtype: Optional<string> = undefined;

      if (purchaseAndContactEventValue !== PurchaseAndContactEventValue.EMAIL) {
        contact_subtype =
          agency.phones.length && isVirtualPhone
            ? ContactSubType.SMART
            : ContactSubType.NO_SMART;
      }

      if (
        (purchaseAndContactEventValue === PurchaseAndContactEventValue.EMAIL ||
          purchaseAndContactEventValue ===
            PurchaseAndContactEventValue.VISIT) &&
        !transaction_id
      ) {
        return;
      }

      let userTrackingData: UserHashedEmailAndPhone = {};

      if (
        (!user ||
          user.email !== contactData?.email ||
          user.phone !== contactData?.phone) &&
        purchaseAndContactEventValue === PurchaseAndContactEventValue.EMAIL
      ) {
        userTrackingData = hashEmailAndPhone({
          email: contactData?.email,
          phone: contactData?.phone,
        });
      }

      sendGA4Event(GATrackingEvent.PURCHASE, {
        ecommerce: {
          currency: 'EUR',
          transaction_id,
          value: purchaseAndContactEventValue,
          items: [],
          item_id: `a_${agency.id}`,
        },
      });

      sendGA4Event(GATrackingEvent.CONTACT, {
        contact_id: transaction_id,
        advertiser: TrackingDataAdvertiser.AGENCY,
        value: purchaseAndContactEventValue,
        contact_category: ContactCategory.AGENCY,
        contact_subtype,
        agency_id: isCourt ? '210238' : agency.id, // id generico usato da app per enti e tribunali
        agency_name: isCourt ? 'Enti e tribunali' : agency.displayName,
        courthouse_id: isCourt ? agency.id : undefined,
        courthouse_name: isCourt ? agency.displayName : undefined,
        contact_type: getContactType(purchaseAndContactEventValue),
        mode_selected: 'lista',
        is_map_collapsed: isMapCollapsed ? 'si' : 'no',
        ...agencyListTrackingData(),
        ...userTrackingData,
      });
    },
    [agencyListTrackingData, isMapCollapsed, sendGA4Event, user]
  );

  return { trackAgencyContactsClick, trackAgencyContactSubmit };
};
