import type { ReadonlyURLSearchParams } from 'next/navigation';

import {
  getObjectFromURLSearchParams,
  serializeIntoUrl,
} from 'src/utils/querystring';

import type { PaginationItemProps } from './PaginationItem';

/**
 * Generate the array of labels which we will show for a given page
 * each value of the array can be a number representing the page for the
 * corresponding pagination item at that index or null if we need to
 * represent the near-edge item (ones with ... label) for that position
 * @param currentPage
 * @param totalPages
 * @param pagesRange
 */
export function generatePaginationRange(
  currentPage: number,
  totalPages: number,
  pagesRange: number // the number of visible items. To get a nice visual balance this should be odd
) {
  if (totalPages < 1) return [];

  const pages = new Set<number>();

  pages.add(1);
  pages.add(totalPages);
  pages.add(currentPage);

  const size = Math.min(totalPages, pagesRange);

  let i = 1;

  while (pages.size < size) {
    if (i % 2 === 0) {
      pages.add(Math.min(totalPages, currentPage + i / 2));
    } else {
      pages.add(Math.max(1, currentPage - (i + 1) / 2));
    }

    i++;
  }

  const range: (number | null)[] = Array.from(pages).sort((a, b) => a - b);

  // Computing the position of near-edge items (ones with ... label)
  if (pagesRange >= 5) {
    // We need to hide the near-edge pages only for ranges with more than
    // four pages
    if (range[1] !== 2) {
      range[1] = null; // First ... element
    }

    if (range[pagesRange - 2] !== totalPages - 1) {
      range[pagesRange - 2] = null; // Last ... element
    }
  }

  return range;
}

const getStatus = (
  currentPage: number,
  totalPages: number,
  disableLastPage: boolean,
  pag?: number
): Optional<'current' | 'disabled'> => {
  if (pag === currentPage) {
    return 'current';
  }

  if (!pag || (pag === totalPages && disableLastPage)) {
    return 'disabled';
  }

  return undefined;
};

export const makePaginationBuilder =
  (
    currentPage: number,
    totalPages: number,
    pathname: string,
    searchParams: ReadonlyURLSearchParams,
    disableLastPage: boolean,
    shallow: boolean
  ): ((pag?: number) => PaginationItemProps) =>
  (pag) => {
    const params = new URLSearchParams(searchParams);

    if (!pag || pag === 1) {
      params.delete('pag');
    } else {
      params.set('pag', String(pag));
    }

    return {
      content: pag || '...',
      link: {
        url: serializeIntoUrl(pathname, getObjectFromURLSearchParams(params)),
      },
      status: getStatus(currentPage, totalPages, disableLastPage, pag),
      shallow,
    };
  };
