export enum BREADCRUMB_TYPE {
  HOMEPAGE = 'homepage',
  MACROAREA = 'macroarea',
  REGIONE = 'regione',
  PROVINCIA = 'provincia',
  COMUNE = 'comune',
  ZONA = 'zona',
  QUARTIERE = 'quartiere',
  AREA_GEOGRAFICA = 'areaGeografica',
  ADDRESS = 'address',
}

export type BreadcrumbItemData = {
  label: string;
  type?: BREADCRUMB_TYPE;
  link?: {
    current?: Nullable<boolean>;
    url: string;
    title?: string;
    follow?: Nullable<boolean>;
  };
  current?: boolean;
  items?: Omit<BreadcrumbItemData, 'items'>[];
  serverSideNavigation?: boolean;
};

export type BreadcrumbData = BreadcrumbItemData[];
