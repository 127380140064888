export const getHasItemProp = (
  hasItemProp: boolean,
  items: boolean,
  link: boolean
) => {
  if (!hasItemProp) {
    return false;
  }

  if (items) {
    return Boolean(link);
  }

  return true;
};
