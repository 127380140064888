import { memo } from 'react';
import { clsx } from 'clsx';

import type { BreadcrumbItemData } from 'src/types/breadcrumb';

import { capitalize } from 'src/utils/string';

import { BreadcrumbLink } from '../BreadcrumbLink';

import css from './styles.module.scss';

export const BreadcrumbOptions = memo(
  ({ items }: { items: BreadcrumbItemData[] }) => (
    <ul className={clsx(css['nd-stack'])}>
      {items.map((item, index) => (
        <li
          className={clsx(
            css['nd-stackItem'],
            css['in-breadcrumbOptions'],
            (item.current || (item.link && Boolean(item.link.current))) &&
              css['is-current']
          )}
          key={index}
        >
          {item.link && item.link.url ? (
            <BreadcrumbLink
              isDropdownLink
              isSelected={Boolean(item.link.current)}
              link={item.link}
              href={item.link.url}
              type={item.type}
              serverSideNavigation={item.serverSideNavigation}
            >
              {capitalize(item.label)}
            </BreadcrumbLink>
          ) : (
            capitalize(item.label)
          )}
        </li>
      ))}
    </ul>
  )
);

BreadcrumbOptions.displayName = 'BreadcrumbOptions';
