import { capitalize, useTranslations } from '@pepita-react/i18n';

import type { ProductConfig } from 'src/config/product';
import { getProductConfig } from 'src/config/product';

import { PepitaList, PepitaListItem } from 'src/libs/ui/pepita-list';

import css from './styles.module.scss';

interface InfoListProps {
  partnership?: string;
  timeContract?: number;
}

export function InfoList({ partnership, timeContract }: InfoListProps) {
  const { trans } = useTranslations();

  const domainName: ProductConfig['domainName'] =
    getProductConfig('domainName');

  const items: string[] = [];

  if (partnership) {
    items.push(
      `${trans('lbl_associated', {
        capitalize: true,
      })} ${partnership}`
    );
  }

  if (timeContract) {
    items.push(
      `${trans('lbl_on_site_since_year', {
        capitalize: true,
        params: [capitalize(domainName), timeContract],
        count: timeContract,
      })}`
    );
  }

  if (items.length === 0) {
    return null;
  }

  return (
    <PepitaList type="pipe" customClass={css['in-agencyInfoList__item']}>
      {items.map((item, i) => (
        <PepitaListItem key={i}>{item}</PepitaListItem>
      ))}
    </PepitaList>
  );
}
