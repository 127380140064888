import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';

import { useNumberFormatterCallback } from 'src/hooks/useFormatters';

import { PepitaBadge } from 'src/libs/ui/pepita-badge';

import css from './styles.module.scss';

interface TagListProps {
  highlighted: boolean;
  realEstateAds?: number;
  realEstateSales?: number;
}

export function TagList({
  realEstateAds,
  realEstateSales,
  highlighted,
}: TagListProps) {
  const { trans } = useTranslations();
  const formatNumber = useNumberFormatterCallback();

  if (!realEstateAds && !realEstateSales) {
    return null;
  }

  return (
    <div
      className={clsx(
        css['in-agencyTagList'],
        highlighted && css['in-agencyTagList--highlighted']
      )}
    >
      {realEstateAds ? (
        <PepitaBadge customClass={css['in-agencyTagList__badge']}>
          <span>
            <strong>{`${formatNumber(realEstateAds)} `}</strong>
            {trans('lbl_ad', {
              count: realEstateAds,
            })}
          </span>
        </PepitaBadge>
      ) : null}

      {realEstateSales ? (
        <PepitaBadge customClass={css['in-agencyTagList__badge']}>
          <span>
            <strong>{`${formatNumber(realEstateSales)} `}</strong>
            {trans('lbl_single_sold_or_rented', {
              count: realEstateSales,
            })}
          </span>
        </PepitaBadge>
      ) : null}
    </div>
  );
}
