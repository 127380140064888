import React from 'react';
import clsx from 'clsx';

import { Footer } from 'src/components/Footer';

import { getProductConfig } from 'src/config/product';

import { useFooterTopConfig } from 'src/hooks/useFooterTopLink';

import css from './styles.module.scss';
interface FooterColumnCompactProps {
  customClass?: string;
}

export function FooterColumnCompact({ customClass }: FooterColumnCompactProps) {
  const hasFooterLinks = getProductConfig('hasFooterLinks');
  const footerTopConfig = useFooterTopConfig('compact');

  return (
    <Footer
      customClass={clsx(css['in-footerColumnCompact'], customClass)}
      footerTop={
        hasFooterLinks
          ? {
              direction: 'column',
              sections: footerTopConfig,
              customClass: css['in-footerColumnCompact__topColumn'],
            }
          : undefined
      }
    />
  );
}
