import type { BannerConfig } from 'src/types/banner';

import type { AgencyBannerConfig } from '.';

export const getValidBanners = (
  banners: Nullable<BannerConfig>,
  agencyBannerConfig: AgencyBannerConfig
) => {
  if (banners) {
    return banners.banners.filter((banner) =>
      banner.key ? Object.values(agencyBannerConfig).includes(banner.key) : null
    );
  }

  return [];
};
