import { Fragment } from 'react';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import { useAtomValue } from 'jotai';

import { AgencyListBanner } from 'src/components/AgencyListBanner';
import { AgencyListItem } from 'src/components/AgencyListItem';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { expandedMapAtom } from 'src/entities/search/components/SearchLayout';

import { PepitaList, PepitaListItem } from 'src/libs/ui/pepita-list';

import type { AgencyListItem as AgencyListData } from 'src/types/agencies-list';

import css from './styles.module.scss';

interface AgencyListProps {
  title?: string;
  agencies: AgencyListData[];
  hasBanner?: boolean;
  hasSeoTag?: boolean;
}

export function AgencyList({
  title,
  agencies,
  hasBanner = false,
  hasSeoTag = false,
}: AgencyListProps) {
  const [minScreenWidthLg] = useBreakpoint({ from: 'lg' });
  const expandedMap = useAtomValue(expandedMapAtom);

  return (
    <>
      {title && <div className={css['sl-agencyList__title']}>{title}</div>}
      <PepitaList
        type="borderBottom"
        customClass={css['sl-agencyList__items']}
        data-cy="result-agency-list"
      >
        {agencies.map((agency, i) => (
          <Fragment key={i}>
            {isFeatureEnabled('AGENCY_LIST_ADV') && hasBanner && i === 2 ? (
              <AgencyListBanner position={i + 1} />
            ) : null}
            <PepitaListItem customClass={css['sl-agencyList__item']}>
              <AgencyListItem
                agency={agency}
                isSmall={minScreenWidthLg && Boolean(expandedMap)}
                hasSeoTag={hasSeoTag}
              />
            </PepitaListItem>
          </Fragment>
        ))}
      </PepitaList>
    </>
  );
}
