import { useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';

import { ContactFormDialog } from 'src/components/ContactForm';

import { useAgencyListItemTracking } from './hooks/useAgencyListItemTracking';

import type { ContactFormData } from 'src/libs/contact';
import { PepitaButton } from 'src/libs/ui/pepita-button';

import { PurchaseAndContactEventValue } from 'src/tracking/utils/enums';

import type { AgencyListItem } from 'src/types/agencies-list';

import { getAgencyForContact } from './utils/getAgencyForContact';

interface AgencyMessageProps {
  agency: AgencyListItem;
  onMessageSubmit: (data: Partial<ContactFormData>) => void;
  onMessageClick: () => void;
}

export function AgencyMessage({
  agency,
  onMessageClick,
  onMessageSubmit,
}: AgencyMessageProps) {
  const { trans } = useTranslations();
  const { trackAgencyContactSubmit } = useAgencyListItemTracking(agency);
  const [open, setOpen] = useState(false);
  const handleMessage = (e) => {
    e.stopPropagation();
    e.preventDefault();

    onMessageClick();
    setOpen(true);
  };

  const handleSubmitMessage = (data: Partial<ContactFormData>) => {
    onMessageSubmit(data);
  };

  return (
    <>
      <PepitaButton icon={{ name: 'chat' }} onClick={handleMessage}>
        {trans('lbl_message')}
      </PepitaButton>
      {open && (
        <ContactFormDialog
          agency={getAgencyForContact(agency)}
          onClose={() => setOpen(false)}
          source="lista_agenzie"
          title={trans('act_ask_informations', { capitalize: true })}
          textareaPlaceholder={trans('contact_form_message_placeholder', {
            capitalize: true,
          })}
          onShowPhoneClick={(phoneType) => {
            trackAgencyContactSubmit({
              agency,
              purchaseAndContactEventValue: PurchaseAndContactEventValue.PHONE,
              isVirtualPhone: phoneType === 'vTel1',
            });
          }}
          onSubmitContact={(data) => {
            handleSubmitMessage(data);
          }}
        />
      )}
    </>
  );
}
