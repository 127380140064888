import clsx from 'clsx';

import css from './styles.module.scss';

export function AgencyDescription({
  description,
  highlighted,
}: {
  highlighted: boolean;
  description?: string;
}) {
  if (!description) {
    return null;
  }

  return (
    <p
      className={clsx(
        css['in-agencyDescription'],
        highlighted && css['in-agencyDescription--highlighted']
      )}
    >
      {description}
    </p>
  );
}
