import type { ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';

import { useDropdown } from '../hooks/useDropdown';

import css from './styles.module.scss';

export type BreadcrumbDropdownProps = {
  children: ReactNode;
  mainRightBounding?: number;
};

export function BreadcrumbDropdown({
  children,
  mainRightBounding,
}: BreadcrumbDropdownProps) {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const container = useRef<Nullable<HTMLElement>>(null);
  const [dropdownPosition, setDropdownPosition] = useState<'left' | 'right'>(
    'left'
  );
  const [rightPosition, setRightPosition] = useState<Nullable<number>>(null);

  useEffect(() => {
    if (dropdownRef.current) {
      container.current = dropdownRef.current.parentElement;
    }
  }, []);

  useEffect(() => {
    if (dropdownRef.current) {
      const dropdownRightBounding =
        dropdownRef.current.getBoundingClientRect().right;
      const dropdownWidthBounding =
        dropdownRef.current.getBoundingClientRect().width;

      const parentRightBounding =
        dropdownRef.current.parentElement?.getBoundingClientRect().right;

      if (mainRightBounding && dropdownRightBounding > mainRightBounding) {
        setDropdownPosition('right');

        if (
          parentRightBounding &&
          parentRightBounding - dropdownWidthBounding < 0
        ) {
          setRightPosition(parentRightBounding - dropdownWidthBounding);
        } else {
          setRightPosition(null);
        }
      } else {
        setDropdownPosition('left');
        setRightPosition(null);
      }
    }
  }, [mainRightBounding]);

  const { isOpen } = useDropdown(container);

  return (
    <div
      data-cy="breadcrumb-dropdown"
      style={rightPosition ? { right: `${rightPosition}px` } : undefined}
      className={clsx(
        css['nd-menu'],
        css['nd-menu--scrollable'],
        css['in-breadcrumbDropdown'],
        dropdownPosition === 'right' && css['in-breadcrumbDropdown--toRight'],
        isOpen && css['is-open']
      )}
      ref={dropdownRef}
    >
      {children}
    </div>
  );
}
