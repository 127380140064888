import paginationSprite from '@pepita-fe/sprite-listing-search/sprite.svg';
import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';

import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import type { PaginationItemProps } from './PaginationItem';
import { PaginationItem } from './PaginationItem';

import css from './index.module.scss';

export type PaginationControlsProps = {
  prevPage?: PaginationItemProps;
  nextPage?: PaginationItemProps;
  firstPage?: PaginationItemProps;
  lastPage?: PaginationItemProps;
};

export type PaginationProps = {
  customClass?: string;
  pages: PaginationItemProps[];
  maxVisiblePages?: number;
  controlEnabled?: boolean;
  controls?: PaginationControlsProps;
  onPageClick?: (page: string) => void;
};

export * from './usePaginationItems';

export type { PaginationItemProps };

export function Pagination({
  customClass,
  pages,
  controls,
  onPageClick,
}: PaginationProps) {
  const { trans } = useTranslations();

  if (pages.length <= 1) return null;

  return (
    <>
      <div className={clsx([css[Styles.element]], customClass && customClass)}>
        {controls && (
          <div className={css[Styles.control]} data-cy="pagination-prev">
            {controls.firstPage && (
              <PaginationItem
                {...controls.firstPage}
                customClass={css[Styles.hideOnMobile]}
                content={
                  <>
                    <PepitaIcon
                      spriteUrl={paginationSprite}
                      name={'double-arrow-left'}
                    />
                  </>
                }
                onClick={() => onPageClick?.('first')}
              />
            )}
            {controls.prevPage && (
              <PaginationItem
                {...controls.prevPage}
                content={
                  <>
                    <PepitaIcon name={'arrow-left'} />
                    <span
                      className={clsx(
                        css[Styles.controlText],
                        css[Styles.showOnMobile]
                      )}
                    >
                      {trans('lbl_previous')}
                    </span>
                  </>
                }
                onClick={() => onPageClick?.('prev')}
              />
            )}
          </div>
        )}
        <div className={css[Styles.list]} data-cy="pagination-list">
          {pages.map((page, i) => {
            return (
              <PaginationItem
                key={i}
                customClass={
                  page.status !== 'current'
                    ? css[Styles.hideOnMobile]
                    : undefined
                }
                {...page}
                onClick={() => {
                  if (onPageClick && page.content) {
                    onPageClick(page.content.toString());
                  }
                }}
              />
            );
          })}
        </div>
        {controls && (
          <div className={css[Styles.control]} data-cy="pagination-next">
            {controls.nextPage && (
              <PaginationItem
                {...controls.nextPage}
                content={
                  <>
                    <span
                      className={clsx(
                        css[Styles.controlText],
                        css[Styles.showOnMobile]
                      )}
                    >
                      {trans('lbl_next')}
                    </span>
                    <PepitaIcon name={'arrow-right'} />
                  </>
                }
                onClick={() => onPageClick?.('next')}
              />
            )}
            {controls.lastPage && (
              <PaginationItem
                {...controls.lastPage}
                customClass={css[Styles.hideOnMobile]}
                content={
                  <>
                    <PepitaIcon
                      spriteUrl={paginationSprite}
                      name={'double-arrow-right'}
                    />
                  </>
                }
                onClick={() => onPageClick?.('last')}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}

export enum Styles {
  element = 'in-pagination',
  list = 'in-pagination__list',
  control = 'in-pagination__control',
  controlText = 'in-pagination__controlText',
  item = 'in-pagination__item',
  icon = 'in-pagination__icon',
  hideOnMobile = 'is-mobileHidden',
  showOnMobile = 'is-mobileVisible',
}
