import type { BreadcrumbItemData } from 'src/types/breadcrumb';
import { BREADCRUMB_TYPE } from 'src/types/breadcrumb';

import { BreadcrumbDropdown } from './BreadcrumbDropdown';
import { BreadcrumbLink } from './BreadcrumbLink';
import { BreadcrumbOptions } from './BreadcrumbOptions';

interface BreadcrumbItemProps {
  option: BreadcrumbItemData;
  index: number;
  hasItemProp?: boolean;
  mainRightBounding?: number;
}

export function BreadCrumbItem({
  option,
  index,
  hasItemProp = false,
  mainRightBounding,
}: BreadcrumbItemProps) {
  if (option.items) {
    return (
      <>
        <BreadcrumbLink
          isDropdownLabel
          role="button"
          itemProp={hasItemProp ? 'item' : undefined}
          link={option.link}
          href={option.link?.url}
          onClick={(event) => event.preventDefault()}
        >
          <span itemProp={hasItemProp ? 'name' : undefined}>
            {option.label}
          </span>
          {hasItemProp && option.link && option.link.url && (
            <>
              <meta itemProp="name" content={option.label} />
              <meta itemProp="url" content={option.link.url} />
            </>
          )}
        </BreadcrumbLink>
        {/* passes the parent's type to the inner options */}
        {option.type &&
          option.items.forEach((item) => {
            item.type = item.type || option.type;
          })}
        <BreadcrumbDropdown mainRightBounding={mainRightBounding}>
          <BreadcrumbOptions items={option.items} />
        </BreadcrumbDropdown>
      </>
    );
  }

  if (option.link && option.link.url) {
    return (
      <BreadcrumbLink
        serverSideNavigation={option.serverSideNavigation}
        itemProp={hasItemProp ? 'item' : undefined}
        link={option.link}
        href={option.link.url}
        type={index === 0 ? BREADCRUMB_TYPE.HOMEPAGE : option.type}
      >
        <span itemProp={hasItemProp ? 'name' : undefined}>{option.label}</span>
      </BreadcrumbLink>
    );
  }

  return (
    <span itemProp={hasItemProp ? 'name' : undefined}>{option.label}</span>
  );
}
