import { useState } from 'react';
import listingSprite from '@pepita-fe/sprite-listing-search/sprite.svg';
import { useTranslations } from '@pepita-react/i18n';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import clsx from 'clsx';

import { PepitaButton } from 'src/libs/ui/pepita-button';

import css from './styles.module.scss';

interface ToggleMapListButtonProps {
  onToggle: (state: 'map' | 'list') => void;
  block?: boolean;
  customClass?: string;
  initialState?: 'map' | 'list';
  iconOnly?: boolean;
}

export function ToggleMapListButton({
  onToggle,
  block = false,
  customClass,
  initialState = 'map',
  iconOnly = false,
}: ToggleMapListButtonProps) {
  const { trans } = useTranslations();
  const [state, setState] = useState<'map' | 'list'>(initialState);
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  return (
    <PepitaButton
      block={block}
      iconOnly={iconOnly}
      icon={{
        name: state === 'list' ? 'list' : 'map',
        spriteUrl: listingSprite,
      }}
      onClick={() => {
        const toggledState = state === 'list' ? 'map' : 'list';

        setState(toggledState);
        onToggle(toggledState);
      }}
      title={
        state === 'list'
          ? trans('lbl_list', { capitalize: true })
          : trans('lbl_map', { capitalize: true })
      }
      customClass={clsx(css['in-toggleMapListButton'], customClass)}
    >
      {!maxScreenWidthSm &&
        (state === 'list' ? trans('lbl_list') : trans('lbl_map'))}
    </PepitaButton>
  );
}
