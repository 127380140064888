import clsx from 'clsx';

import { useAgencyListItemTracking } from './hooks/useAgencyListItemTracking';

import { PepitaMediaObjectBottom } from 'src/libs/ui/pepita-media-object';

import { PurchaseAndContactEventValue } from 'src/tracking/utils/enums';

import type { AgencyListItem } from 'src/types/agencies-list';

import { AgencyMessage } from './AgencyMessage';
import { AgencyPhone } from './AgencyPhone';

import css from './styles.module.scss';

interface AgencyActionsProps {
  agency: AgencyListItem;
  isSmall: boolean;
}

export function AgencyActions({ agency, isSmall }: AgencyActionsProps) {
  const { phones } = agency;
  const { trackAgencyContactsClick, trackAgencyContactSubmit } =
    useAgencyListItemTracking(agency);

  return (
    <PepitaMediaObjectBottom
      customClass={clsx(css['in-agencyActions'], isSmall && css['is-compact'])}
    >
      {!isSmall && phones.length > 0 ? (
        <AgencyPhone
          type={phones[0].type}
          phoneNumber={phones[0].value}
          onShowPhoneClick={() => {
            trackAgencyContactSubmit({
              agency,
              purchaseAndContactEventValue: PurchaseAndContactEventValue.PHONE,
              isVirtualPhone: phones[0].isVirtual,
            });
            trackAgencyContactsClick({
              type: 'telephone',
              phoneType: phones[0].type,
              isGreenPhone: phones[0].type === 'green-phone',
            });
          }}
        />
      ) : null}

      <AgencyMessage
        agency={agency}
        onMessageSubmit={(data) => {
          trackAgencyContactSubmit({
            agency,
            purchaseAndContactEventValue: PurchaseAndContactEventValue.EMAIL,
            isVirtualPhone: false,
            contactData: data,
          });
        }}
        onMessageClick={() => {
          trackAgencyContactsClick({
            type: 'message',
          });
        }}
      />
    </PepitaMediaObjectBottom>
  );
}
