import clsx from 'clsx';

import css from './styles.module.scss';

interface AgencyAddressProps {
  address?: string;
  isSmall: boolean;
  highlighted: boolean;
}

export function AgencyAddress({
  address,
  isSmall,
  highlighted,
}: AgencyAddressProps) {
  if (!address) {
    return null;
  }

  return (
    <p
      className={clsx(
        css['in-agencyAddress'],
        isSmall && css['is-small'],
        highlighted && css['is-highlighted']
      )}
    >
      {address}
    </p>
  );
}
