import { useEffect, useReducer, useRef } from 'react';
import type {
  QueryObserverResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { deepEqual } from 'src/utils/object';

/**
 * This is a wrapper around useQuery in order to instantly invalidate the result
 *
 * Useful when the query result is used as dependency for the enabled flag of another query to
 * instantly disable the dependant on key change
 */
export const useQueryWithInstantInvalidation = <
  TData = unknown,
  TError = unknown,
  TQueryFnData = TData,
>(
  options: UseQueryOptions<TData, TError, TQueryFnData>
): UseQueryResult<TData, TError> => {
  const forceRender = useReducer((state) => !state, true)[1];

  const currentResult = useQuery({
    ...options,
  }) as QueryObserverResult<TData, TError>;
  const { queryKey } = options;
  const result = useRef(currentResult);
  const prev = useRef(queryKey);

  if (!deepEqual(prev.current, queryKey)) {
    result.current = {
      ...currentResult,
      data: undefined,
      error: undefined,
    };
    prev.current = queryKey;
  }

  useEffect(() => {
    result.current = currentResult;
    forceRender();
  }, [currentResult.data, currentResult.error]);

  return result.current;
};
