import type { SyntheticEvent } from 'react';

import {
  PepitaMediaObjectContent,
  PepitaMediaObjectInteractive,
  PepitaMediaObjectTop,
} from 'src/libs/ui/pepita-media-object';

import type { AgencyListItem as AgencyListData } from 'src/types/agencies-list';

import { AgencyActions } from './Actions';
import { AgencyAddress } from './Address';
import { AgencyLogo } from './AgencyLogo';
import { AgencyDescription } from './Description';
import { InfoList } from './InfoList';
import { TagList } from './TagList';
import { AgencyTitle } from './Title';

import css from './styles.module.scss';

interface AgencyListItemProps {
  agency: AgencyListData;
  isSmall?: boolean;
  hasSeoTag?: boolean;
}

export function AgencyListItem({
  agency,
  isSmall = false,
  hasSeoTag = false,
}: AgencyListItemProps) {
  const {
    address,
    agencyUrl,
    description,
    displayName,
    imageUrls,
    partnership,
    realEstate,
    realEstateSales,
    timeContract,
    type,
    highlighted,
  } = agency;

  const handleOnClick = (evt: SyntheticEvent) => {
    if (!agencyUrl) return;
    evt.preventDefault();
    window.open(agencyUrl, '_blank');
  };

  const title = (
    <AgencyTitle
      highlighted={highlighted}
      isSmall={isSmall}
      title={displayName}
      href={agencyUrl}
    />
  );

  return (
    <PepitaMediaObjectInteractive
      direction="colToRow"
      customClass={css['in-agListItem']}
      onClick={handleOnClick}
    >
      <AgencyLogo
        imageAlt={displayName}
        agencyType={type}
        imageUrls={imageUrls?.large ?? undefined}
        highlighted={highlighted}
        isSmall={isSmall}
      />
      <PepitaMediaObjectContent>
        <PepitaMediaObjectTop>
          {hasSeoTag ? <h3>{title}</h3> : title}
          {!isSmall && (
            <InfoList partnership={partnership} timeContract={timeContract} />
          )}

          <AgencyAddress
            address={address}
            isSmall={isSmall}
            highlighted={highlighted}
          />

          {!isSmall && (
            <TagList
              highlighted={highlighted}
              realEstateAds={realEstate}
              realEstateSales={realEstateSales}
            />
          )}

          {!isSmall && (
            <AgencyDescription
              highlighted={highlighted}
              description={description}
            />
          )}
        </PepitaMediaObjectTop>
        <AgencyActions agency={agency} isSmall={isSmall} />
      </PepitaMediaObjectContent>
    </PepitaMediaObjectInteractive>
  );
}
